
import React from "react";
import HeadsUp from "../../HeadsUp";
import stockToggleSrc from "./stockToggle.png"
import pokerBoxOptions from "./pokerBoxOptions.png"
import completeComponent from "./completeComponent.png"

export default class CreatingComponents extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Creating Components</h1>
            <p>Create components to add products like deck and dice to your game. All components that can be purchased from TheGameCrafter can be managed by Templative.</p>
            <HeadsUp message="Templative is not designed to assist with custom minis."/>
            <p>To create a new component, go to the Create Components screen. From there we are presented with many, many options for the type of component we want to create.</p>
            <p>First we must choose if we want to create a custom component, like a new poker deck, or if we want add stock components, like standard dice, to our game using the toggle.</p>
            <img src={stockToggleSrc} alt="The stock vs custom component type toggle"/>
            <p>Then we filter our component type options by tags. To find packaging for our mostly poker deck game we might select `box` then `poker`. You may be suprised to find the kinds of board game components you can make. Now that we've filtered to poker boxes, we can see our new options.</p>
            <img src={pokerBoxOptions} alt="Selecting the box and poker tags to find good poker boxes."/>
            <p>Select a component type, give it a name, and press create. This creates the files for our component, and clears our selection so that we might add more components.</p>
            <img src={completeComponent} alt="Our completed component."/>
            <p>Now we can proceed to editing our new component in the Edit Component screen, read on in the <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/art`}>Art</a> article.</p>

        </React.Fragment>
    }
}