
import React from "react";
import "./Landing.css";

import {TopBar} from "./TopBar/TopBar"
import CompanyInfo from "./CompanyInfo/CompanyInfo"
import Footer from "./Footer/Footer"
import Logo from "../Logo";

export default class Landing extends React.Component {
    render() {
        return (
            <div className="row landing">
                <TopBar logoutCallback={this.props.logoutCallback} isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative} email={this.props.email}/>
                <Logo/>
                <div className="firstDiv"></div>

                <div className="row main-content-row g-0">
                    <div className="col main-content-col">
                        {this.props.children}
                    </div>
                </div>

                <div className="firstDiv"></div>
                <CompanyInfo companyName={this.props.companyName} supportEmail={this.props.supportEmail}/>
                <Footer companyName={this.props.companyName}/>
            </div>
        );
    }
}