
import React from "react";
import conventionSearch from "./conventionSearch.png"
import TemplativePurchaseSuggestion from "../../TemplativePurchaseSuggestion";

export default class AttendingaConvention extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Attending a Convention</h1>
            <p>Conventions are a great place to show off your game, get testers, and meet fellow designers.</p>
            <TemplativePurchaseSuggestion isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative}/>
            <p>To see upcoming conventions within Templative, go to the Find Conventions and Publishers map. All orange markers are upcoming Conventions this year. Conventions that are coming up soon are larger on the map.</p>
            <img src={conventionSearch} alt="A map of conventions."/>
        </React.Fragment>
    }
}