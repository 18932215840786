
import React from "react";
import "./DemoRow.css"
import { postADemoRequest } from "../../../TemplativeClient";
import { getCookie,setCookie } from "../../../CookieManagement";

export default class DemoRow extends React.Component {
    state = {
        errorMessage: "",
        selectedImage: null,
        imageFile: null,
        email: "",
        hasInheritedEmail: false,
        demoEmailSender: null,
        isSendingLocked: false
    }
    componentDidMount = async () => {
        if (this.props.email) {
            this.setState({email: this.props.email, hasInheritedEmail: true})
        }
        var demoSender = getCookie("DEMO_EMAIL_SENDER") 
        var hasDemoSender = demoSender !== null
        if (hasDemoSender) {
            this.setState({ demoEmailSender: demoSender })
        }
    }
    updateEmail = (e) => {
        this.setState({ email: e.target.value, errorMessage: "" });
    }
    setImage = async (e) => {
        const file = e.target.files[0];
        if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
            this.setState({
                selectedImage: URL.createObjectURL(file),
                errorMessage: "",
                imageFile: file
            });
        } else {
            this.setState({
                errorMessage: 'Please upload a valid JPEG or PNG image.',
                selectedImage: null,
                imageFile: null
            });
        }
    }
    sendDemo = async () => {
        this.setState({isSendingLocked:true})
        if (this.state.email && this.state.imageFile) {
            try {
                await postADemoRequest(this.state.email, this.state.imageFile)
                setCookie("DEMO_EMAIL_SENDER", this.state.email, 999)
                this.setState({demoEmailSender: this.state.email})
            } catch (error) {
                console.log('Failed to submit demo request:', error);
                this.setState({ errorMessage: 'There was an issue with posting the demo.' });
            }
        } else {
            this.setState({ errorMessage: 'Please provide both an email and an image.' });
        }
        this.setState({isSendingLocked:false})
        
    }
    render() {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        var isInvalidEmail = !emailRegex.test(this.state.email);
        var sendDisabled = this.state.selectedImage === null || isInvalidEmail || this.state.isSendingLocked
        return (
            <div className="row justify-content-center demo-row">
                <div className="col">
                    <div className="demo-form">
                        { this.state.demoEmailSender !== null ? 
                            <div>
                                <h1>Thanks for sending a demo in!</h1>
                                <p className="wait-for-response">Check <span className="your-email">{this.state.demoEmailSender}</span> for a response from me at oliver@gonextgames.com.</p>
                            </div>
                            :
                            <div>
                                <h1>Confused? I'll make a demo for you.</h1>
                                <p>Worried it will be too hard to setup? Send me example card of one of your board games and I'll remake it in Templative.</p>
                                
                                {(!this.state.hasInheritedEmail) && 
                                <div className="input-group input-group-sm" data-bs-theme="dark">
                                    <span className="input-group-text">Email</span>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Contact email address"
                                        value={this.state.email}
                                        onChange={this.updateEmail}
                                        />
                                </div>
                                }
                                <p className="image-suggestion">Choose a representative image for me to remake.</p>
                                <div className="input-group input-group-lg" data-bs-theme="dark">
                                    <input className="choose-demo-file" type="file" accept="image/jpeg, image/png" onChange={this.setImage} />
                                </div>
                                {this.state.errorMessage && <p style={{ color: 'palevioletred' }}>{this.state.errorMessage}</p>}
                                {this.state.selectedImage && 
                                    <div className="demo-image-wrapper">
                                        <img src={this.state.selectedImage} alt="Selected" style={{ maxWidth: '10em', height: 'auto' }} />
                                    </div>
                                }
                                
                                <div className="input-group input-group-lg submit-input-group" data-bs-theme="dark">
                                    <button type="button" className="btn submit-demo-btn" onClick={this.sendDemo} disabled={sendDisabled}>Submit</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
