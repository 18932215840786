export const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';

    document.cookie = name + "=" + (value || "") + expires + `; path=/; ${isLocalhost? "": "Secure;"} SameSite=Strict`;
}
export const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}
export const clearCookie = (name, path = '/', domain = '') => {
    if (document.cookie.indexOf(name + '=') !== -1) {
        document.cookie = name + '=' +
            '; path=' + path +
            (domain ? '; domain=' + domain : '') +
            '; expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
}