import React from "react";
// import HeadsUp from "../../../../Documentation/HeadsUp";
// import TemplativePurchaseSuggestion from "../../../../Documentation/TemplativePurchaseSuggestion";

export default class Wheretosellmygame extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Where to sell my game</h1>
            <p></p>
        </React.Fragment>
    }
}