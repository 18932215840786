import React from "react";
// import HeadsUp from "../../../../Documentation/HeadsUp";
// import TemplativePurchaseSuggestion from "../../../../Documentation/TemplativePurchaseSuggestion";

export default class Howtostart extends React.Component {
    render() {
        return <React.Fragment>
            <h1>How did I start making board games?</h1>
            <p>Making board games is fun and satisfying work.</p>
            <p>Whether you are an experience game designer or completely new to the field, board game projects always begin in a humble way. The only thing I need when I make my board game prototypes is a pen, paper, and some notecards.</p>
            <p>I got started making board games as part of learning to make video games oddly enough. Video games can do crazy things, but often require teams of competent people working together in harmony, whereas a board game could reasonably be cooked up in a weekend.</p>
        </React.Fragment>
    }
}