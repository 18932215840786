
import React from "react";
import gamedata from "./gamedata.png"
import exampleCard from "./exampleCard.png"
import gameGamedata from "./gameGamedata.png"
import studioGamedata from "./studioGamedata.png"
import pieceGamedata from "./pieceGamedata.png"
import componentGamedata from "./componentGamedata.png"
import onePiece from "./onePiece.png"
import lockedField from "./lockedField.png"
import victoryPointsGamedata from "./victoryPointsGamedata.png"
import victoryPointsOutput from "./victoryPointsOutput.png"
import pipedGamedata from "./pipedGamedata.png"
import HeadsUp from "../../../HeadsUp";
export default class Gamedata extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Gamedata</h1>
            <p>Use Gamedata to tell Templative what to put in the <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/art`}>Art</a> when commanded by the <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/artdata`}>Artdata</a> files.</p>
            <p>Every singular piece of your board game is made up of concentric layers of gamedata.</p>
            <p>For instance, one of your cards might be called "Orc Warrior", but it might get it's red faction color from the "Orcs" deck it is a part of, while the "Orcs vs Humans" game title and the "Orc Stans Ltd" studio name in the bottom right would be from the game and studio gamedata files respectively.</p>
            <img src={exampleCard} alt="An example card."/>
            <p>A piece inherits all the data from its component, game, and studio it is a child of.</p>
            <img src={gamedata} alt="Concentric gamedata."/>
            <p>When defining content for my game, rather than writing "red factionColor" in each piece's gamedata, we instead define factionColor in the component gamedata. Similarly, content that is common among all components goes in the game or studio gamedata.</p>
            <p>Consider the editing process and example at the bottom.</p>
            
            <h2>Editing Gamedata</h2>
            <p>Add and update fields in each of the Gamedata files to make them available to the Artdata commands to update Art with.</p>
            <p>The studio and gamedata files are accessible from the Edit components screen. The game and studio tabs are always available at the top.</p>
            <HeadsUp message="Be careful of deleting default fields in the gamedata files, they may be used by Templative."/>
            <p>Studio Gamedata:</p>
            <img src={studioGamedata} alt="The studio's gamedata."/>
            <p>Game Gamedata:</p>
            <img src={gameGamedata} alt="The game's gamedata."/>
            <p>Each component you create gets it's own component gamedata file in the Component Gamedata directory.</p>
            <img src={componentGamedata} alt="The components's gamedata."/>
            <p>Each component you create gets it's own piece gamedata file in the Piece Gamedata directory. This file contains the data of multiple pieces.</p>
            <img src={pieceGamedata} alt="The piece's gamedata."/>
            <p>When editing a specific piece, give it a name, for filenames, a displayName, for when a pretty name of the piece is given, a quantity, and any number of fields.</p>
            <img src={onePiece} alt="An example piece gamedata."/>
            <p>Delete a piece with the trash can. Duplicate it with the overlapping pages icon. Add a field to all peices in the file with the + button at the bottom of a piece. Delete a field from all pieces by clicking the trash can on the line of that field. Add a new piece by clicking `Create a New Piece` at the top.</p>
            <p>Lock a field so that you only see that one field for all pieces using the lock icon.</p>
            <img src={lockedField} alt="All fields are locked."/>
            <p>This is useful for when your pieces get large and unwieldy.</p>
            
            <h2>An Example of Using Gamedata</h2>
            <p>This Victory Points artdata file replaces every instead of {"{value}"} found in the art file with the Piece's value.</p>
            <img src={pipedGamedata} alt="Example art data piping a piece's value."/>
            <p>In my case, I had three Victory Point chits with values of 1, 5, 10 respectively.</p>
            <img src={victoryPointsGamedata} alt="The Victory Points piece's gamedata."/>
            <p>Resulting in...</p>
            <img src={victoryPointsOutput} alt="Our victory point output."/>
            
        </React.Fragment>
    }
}