
import React from "react";
import componentComposeExample from "./componentComposeExample.png"
import fileOptions from "./fileOptions.png"
import HeadsUp from "../../../HeadsUp";
export default class ComponentCompose extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Component Compose</h1>
            <p>Use the components-compose file to tell Templative how to assemble a component using <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/artdata`}>Artdata</a> and <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/gamedata`}>Gamedata</a> files.</p> 
            <p>The tab for your game's component-compose is always available at the top of the screen.</p>
            <h2>Understanding File References</h2>
            <p>Each component has a name, type, quantity, artdata, and gamedata. Take a look a this example Mint Tin Deck named countries.</p>
            <img src={componentComposeExample} alt="An example component called countries"/>
            <p>This blurb shows us what files are used to create country mint tin cards. Namely:</p>
            <ul>
                <li>A <strong>component-level gamedata</strong> file named countries.json in the gamedata/componentGamedata folder. This will contain any data common to all countries.</li>
                <li>A <strong>piece-level gamedata file</strong> named countries.json in the gamedata/piecesGamedata folder. This will contain data that is unique to each card.</li>
                <li>An <strong>front artdata file</strong> named countriesFront.json in the /artdata folder that tells us how the <em>front</em> art file of countries is assembled</li>
                <li>An <strong>back artdata file</strong> named countriesBack.json in the /artdata folder that tells us how the <em>back</em> art file of countries is assembled</li>
            </ul>
            <p>If the file you name exists, then a clickable arrow button appears that will open that file in Templative.</p>
            <p>If you want to refer to a file in a folder, for instance to point to an older version of your gamedata, you might write `oldGamedata/countries`.</p>
            <HeadsUp message="Do not include extensions like .json in your file references."/>
            <p>If you want to change which file a component looks at, clicking on an input will give you suggestions:</p>
            <img src={fileOptions} alt="Suggested file names"/>
            <h2>Modifying Components</h2>
            <p>New components can be created using the <a href={`${window.location.origin}/docs/1-making-your-game/creating-components`}>Create Components tab</a>.</p>
            <p>You can increase the quantity of a given deck by incrementing the quantity field of the component.</p>
            <p>You can delete a component by clicking the trash can icon when you hover over a component. This does <strong>not</strong> delete the files the component refers to, it deletes the component and the files remain.</p>
            <p>You can duplicate a component by pressing the overlapping pages icon when you hover over a component. This does <strong>not</strong> duplicate the files the component refers to, it creats a new component that relies on those files.</p>

        </React.Fragment>
    }
}