
import React from "react";
import "./Rows.css"
import "./HowItWorksRow.css"
import template from "./template.gif"
import examples from "./examples.gif"
import exporting from "./exporting.gif"
import printerImage from "./printer.png"
import tabletopPlaygroundImage from "./tabletopPlayground.png"
import theGameCrafterLogoImage from "./thegamecrafterLogo.png"
const howItWorks = [
    {
        title: "1. Create Art in Inkscape",
        description: <>Create vector art with fields to be filled in like <span className="highlighted-how-to">name</span> or <span className="highlighted-how-to">setupRules</span> and label important elements with ids.</>,
        image: template
    },
    {
        title: "2. Assemble your Game",
        description: "Automagically layer your art files, change names, and update style based on your game content. Instead of manually changing every card, propogate it everywhere!",
        image: examples
    },
    {
        title: "3. Export Everywhere",
        description: "Buy a license to upload to the GameCrafter, create printable PDFs, make Tabletop Simulator/Playground mods with one button press.",
        image: exporting
    },
]

export default class HowItWorksRow extends React.Component {
    render() {
        var elements = howItWorks.map((element, index) => {
            const isOdd = index % 2
            const content = <div className="col-12 col-sm">
                <h2>{element.title}</h2>
                <p>{element.description}</p>
            </div>
            var alt = element.description.toString()
            const image = <div className="col-12 col-md">
                <div className="how-to-content"><img className="how-to-image" src={element.image} alt={alt}/></div>
            </div>
            return <div key={element.title} className="row how-to-section">
                { isOdd ? image : content}
                { isOdd ? content : image}
            </div>
        })
        return (
            <div className="row justify-content-center how-to-sections">
                <div className="col-12">
                    <h1>How does it work?</h1>
                    <div className="how-to-wrapper">
                        {elements}
                    </div>
                    {/* <h1>Easy Export!</h1> */}
                    <div className="export-icons">
                        <img className="exportPathImage" src={theGameCrafterLogoImage} alt="TheGameCrafter Logo"/>
                        <img className="exportPathImage" src={tabletopPlaygroundImage} alt="Tabletop Playground Logo"/>
                        <img className="exportPathImage" src={printerImage} alt="Printer"/>
                    </div>
                </div>
            </div>
        );
    }
}
