
import React from "react";
import {attemptPurchase} from "../../TemplativeClient"
export default class TemplativePurchaseSuggestion extends React.Component {
    state = {
        isHovering: false,
    }
    handleMouseOver = () => {
        this.setState({isHovering: true})
    }
    handleMouseOut = () => {
        this.setState({isHovering: false})
    }
    
    static getOS = () => {
        var platform = window.navigator.platform
        var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
        var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          return 'darwin';
        } 
        if (windowsPlatforms.indexOf(platform) !== -1) {
          return 'win32';
        }
      
        return undefined;
      }
    register = () => {
        window.location.href = `${window.location.origin}/register`         
    }
    render() {
        console.log(this.props)
        if (this.props.isLoggedIn && this.props.doesOwnTemplative) {
            return <></>
        }
        var message = "Register Now!"
        var onClickAction = this.register
        if (this.props.isLoggedIn && !this.props.doesOwnTemplative) {
            message = "Unlock Now!";
            onClickAction = attemptPurchase;
        }
        return <div className="templative-purchase-suggestion"
            onMouseOver={this.handleMouseOver}
            onMouseLeave={this.handleMouseOut}
        >
            { this.state.isHovering ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-unlock-fill" viewBox="0 0 16 16">
                    <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2"/>
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16">
                    <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2"/>
                </svg>
            }
            <p>This is a paid feature for serious game designers.</p>
            <button 
                onClick={onClickAction} 
                disabled={TemplativePurchaseSuggestion.getOS() === undefined}
                type="button" className="btn btn-primary suggested-purchase-button"
            >
                {message}
            </button>
        </div>
    }
}