
import React from "react";
import "./DownloadRow.css"
import YouTube from 'react-youtube';
import { attemptTemplativeDownload,attemptPurchase } from "../../../TemplativeClient";
import ReactGA from 'react-ga4';
import ReminderRow from "./Reminder/ReminderRow";

export default class DownloadRow extends React.Component {
    state = {
        isDownloading: false
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    _onPlay() {
      window.rdt('track', 'Custom', {customEventName:'playVideo'});
    }
    static getOS = () => {
        var platform = window.navigator.platform
        var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
        var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          return 'darwin';
        } 
        if (windowsPlatforms.indexOf(platform) !== -1) {
          return 'win32';
        }
      
        return undefined;
    }
      
    clickDownloadButton = async () => {
        if (DownloadRow.getOS() === undefined || this.state.isDownloading) {
            return false
        }
        ReactGA.event({
            category: 'User',
            action: 'Templative Downloaded',
            label: 'App Download',
          });
        window.rdt('track', 'Custom', {customEventName:'Templative Downloaded'});
        this.setState({isDownloading: true}, async () => {
            await attemptTemplativeDownload()
            this.setState({isDownloading: false})
        })
    }    
    
    register = () => {
        window.location.href = `${window.location.origin}/register`        
    }
    visitDiscord = () => {
        ReactGA.event({
            category: 'User',
            action: 'Visited Discord',
            label: 'Discord Link',
          });
        window.rdt('track', 'Custom', {customEventName:'Visited Discord'});
        
        window.open("https://discord.gg/GN3vgjHegK", '_blank').focus();
    }
    
    render() {   
        var primaryActions = {
            "guest": {"wording": "Unlock Export Features", callback: this.register},
            "not-purchased": {"wording": "Unlock Export Features", callback: attemptPurchase},
            "purchased": {"wording": "Download Templative", callback: this.clickDownloadButton},
        }
        var primaryAction = primaryActions["purchased"]//this.props.status]
        var isSecondaryDownloadButtonShown = false;//this.props.status === "guest" || this.props.status === "not-purchased"
        return (
            <div className="row justify-content-center download-row">
                <div className="col-12 col-xl-5 pitch-col">
                        <h1 className="headline">Automate board game dev</h1>
                        <h2 className="secondary-headline">with the #1 code-free, AI-free desktop app</h2>
                        <ul className="feature-list">
                            <li>Update hundreds of art files easily</li>
                            <li>One-button-press GameCrafter uploads, printable pdfs, and Tabletop Playground packages</li>
                            <li>Collaborate with Git and Drive apps</li>
                        </ul>
                        <p className="headline-description"></p>
                        <div className="secondary-action-buttons-row">
                            <button onClick={primaryAction.callback} 
                                disabled={DownloadRow.getOS() === undefined || this.state.isDownloading}
                                type="button" className="btn btn-primary primary-action-button" >
                                {primaryAction.wording}
                            </button>
                            {isSecondaryDownloadButtonShown && 
                                <button 
                                    onClick={this.clickDownloadButton} 
                                    disabled={DownloadRow.getOS() === undefined || this.state.isDownloading}
                                    type="button" className="btn btn-primary secondary-action-button"
                                >
                                    Download Templative
                                </button>
                            }
                            <button type="button" className="btn btn-primary discord-button" onClick={this.visitDiscord}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                                <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
                                </svg>
                            </button>
                    </div>
                    <ReminderRow/>
                </div>
                <div className="col-12 col-xl-5 youtube-column">
                    <YouTube videoId="qT5x21iLpjA" opts={{ height: '390', width: '640', playerVars: { autoplay: 0} }} onReady={this._onReady} onPlay={this._onPlay} />
                </div>
            </div>
        );
    }
}
