import React from "react";
import "./CompanyInfo.css"
import LinkList from "./LinkList";

export default class CompanyInfo extends React.Component {
    render() {
        return (
            <div className={"company-info col-12 dark"} >
                <div className="row justify-content-between">
                    <div className=".d-none .d-sm-block col-md-3 company-info-name">
                        <p className="company-shout">Go Next Games</p>
                    </div>
                    <div className="col-12 col-md-6">
                        <LinkList listName="Company" links={[
                            {
                                url: "/privacy",
                                name: "Privacy Policy",
                                type: "localLink",
                            },
                            {
                                url: "/terms",
                                name: "Terms and Conditions",
                                type: "localLink",
                            }
                        ]}/>
                        <LinkList listName="Support" links={[
                            {
                                url: `mailto:${this.props.supportEmail}`,
                                name: "Contact Us",
                                type: "href",
                            },
                        ]}/>
                    </div>
                </div>
            </div>
        );
    }
}