
import React from "react";
import HeadsUp from "../../HeadsUp";

export default class CollaborationandVersionControl extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Collaboration and Version Control</h1>
            <p>Templative does not stpore your games on our servers because Templative projects are entirely local projects.</p>
            <p>Because of this, Templative easily plugs into <a href="https://git-scm.com/">Git</a>, a version control system, and Drive Apps like <a href="https://drive.google.com">Google Drive</a> and <a href="https://www.microsoft.com/en-us/microsoft-365/onedrive/online-cloud-storage">One Drive</a>.</p>
            <h2>Git Setup</h2>
            <p>Once Git is installed on your computer, run `git init` to initalize the repository in the Templative project.</p>
            <h2>Drive Setup</h2>
            <p>In order to utilize Drive apps with Templative, you must download the desktop app version of the Drive apps. From there, create a folder for your project, and use that folder when creating a new Templative project.</p>
            <HeadsUp message="Be careful! Editing a file at the same time as a teammate may overwrite their work, though both Drive apps have history features."/>
            <p>Changes made by others will be propogated to you, albiet with delay.</p>
        </React.Fragment>
    }
}