import HowdoIcheaplyprototype from './articles/./Budgeting and Finances/How do I cheaply prototype.js';
import HowdoImanagehowmuchtospend from './articles/./Budgeting and Finances/How do I manage how much to spend.js';
import HowdoImanageinventory from './articles/./Budgeting and Finances/How do I manage inventory.js';
import HowdoImanagerisk from './articles/./Budgeting and Finances/How do I manage risk.js';
import HowmuchdoIchargeforthegame from './articles/./Budgeting and Finances/How much do I charge for the game.js';
import Howtostart from './articles/./How to start.js';
import Howtomakeagamefun from './articles/./Ideation/How to make a game fun.js';
import Howtomakeameaningfulgame from './articles/./Ideation/How to make a meaningful game.js';
import Howtomakehighleveragegames from './articles/./Ideation/How to make high leverage games.js';
import Whatgametomake from './articles/./Ideation/What game to make.js';
import HowdoImakemygameaccessible from './articles/./Instantiation/How do I make my game accessible.js';
import HowdoImakemygamesensitiveandinclusive from './articles/./Instantiation/How do I make my game sensitive and inclusive.js';
import HowdoImakemyproductstandout from './articles/./Instantiation/How do I make my product stand out.js';
import Howtochoosewhichideatoworkon from './articles/./Instantiation/How to choose which idea to work on.js';
import Howtoexplainmygameonthebox from './articles/./Instantiation/How to explain my game on the box.js';
import Howtokeepupwithcontenttreadmill from './articles/./Instantiation/How to keep up with content treadmill.js';
import Howtomakeaboardgamebox from './articles/./Instantiation/How to make a board game box.js';
import Howtomakeart from './articles/./Instantiation/How to make art.js';
import Howtoprototypemydesigns from './articles/./Instantiation/How to prototype my designs.js';
import Howtorapidprototype from './articles/./Instantiation/How to rapid prototype.js';
import Howtotranslatemyideaintoagame from './articles/./Instantiation/How to translate my idea into a game.js';
import HowtouseAItomakemygame from './articles/./Instantiation/How to use AI to make my game.js';
import Howtowriterulesclearly from './articles/./Instantiation/How to write rules clearly.js';
import Whattoprototypewith from './articles/./Instantiation/What to prototype with.js';
import HowdoImakeacontractwithcontractors from './articles/./Legal/How do I make a contract with contractors.js';
import HowdoIwriteacontract from './articles/./Legal/How do I write a contract.js';
import Hownottogetsued from './articles/./Legal/How not to get sued.js';
import HowtoprotectmyIP from './articles/./Legal/How to protect my IP.js';
import WhatdatashouldIcollectandwhatarethelaws from './articles/./Legal/What data should I collect and what are the laws.js';
import Howtofindafulfillmentcompany from './articles/./Manufacturing/Fulfillment/How to find a fulfillment company.js';
import Whatdoesafulfillmentcompanyneed from './articles/./Manufacturing/Fulfillment/What does a fulfillment company need.js';
import Whatisafulfillmentcompany from './articles/./Manufacturing/Fulfillment/What is a fulfillment company.js';
import Howmanyboxes from './articles/./Manufacturing/How many boxes.js';
import HowmanymanufacturersdoIapproach from './articles/./Manufacturing/How many manufacturers do I approach.js';
import Howmuchtochargeforthegame from './articles/./Manufacturing/How much to charge for the game.js';
import Howtocontactamanufacturer from './articles/./Manufacturing/How to contact a manufacturer.js';
import Howtocreateprintandplay from './articles/./Manufacturing/How to create print and play.js';
import Howtofindamanufacturer from './articles/./Manufacturing/How to find a manufacturer.js';
import Howtomakemygamecheaply from './articles/./Manufacturing/How to make my game cheaply.js';
import HowtouploadtotheGameCrafter from './articles/./Manufacturing/How to upload to the GameCrafter.js';
import HowdoImakesureshippingischeap from './articles/./Manufacturing/Shipping/How do I make sure shipping is cheap.js';
import Howmuchtochargeshipping from './articles/./Manufacturing/Shipping/How much to charge shipping.js';
import Howtoavoidtaxesandfeesshippingoverseas from './articles/./Manufacturing/Shipping/How to avoid taxes and fees shipping overseas.js';
import Whattimeofyeartoship from './articles/./Manufacturing/Shipping/What time of year to ship.js';
import Whohandlestheshipping from './articles/./Manufacturing/Shipping/Who handles the shipping.js';
import Whatdomanufacturersneed from './articles/./Manufacturing/What do manufacturers need.js';
import Whentocontactmanufacturers from './articles/./Manufacturing/When to contact manufacturers.js';
import Whomakesmygame from './articles/./Manufacturing/Who makes my game.js';
import HowdoIgetasenseofthemarket from './articles/./Market Research/How do I get a sense of the market.js';
import HowdoIknowwhatotehrsaredoing from './articles/./Market Research/How do I know what otehrs are doing.js';
import HowdoIcreateafacebookad from './articles/./Marketing/Ads/How do I create a facebook ad.js';
import HowmuchdoIspendonmarketing from './articles/./Marketing/Ads/How much do I spend on marketing.js';
import WhatplatformsdoIcreateadson from './articles/./Marketing/Ads/What platforms do I create ads on.js';
import Whatshouldmyadlooklike from './articles/./Marketing/Ads/What should my ad look like.js';
import Howoftentowriteinmyblog from './articles/./Marketing/Blogging/How often to write in my blog.js';
import Isbloggingrightformyproduct from './articles/./Marketing/Blogging/Is blogging right for my product.js';
import Whatplatformtoblogin from './articles/./Marketing/Blogging/What platform to blog in.js';
import Whattowriteinmyblog from './articles/./Marketing/Blogging/What to write in my blog.js';
import Whotoblogto from './articles/./Marketing/Blogging/Who to blog to.js';
import Doesmyproductworkwithclickbaitmarketing from './articles/./Marketing/Buzz/Does my product work with clickbait marketing.js';
import ShouldIusehumor from './articles/./Marketing/Buzz/Should I use humor.js';
import Whatisclickbaitcontent from './articles/./Marketing/Buzz/What is clickbait content.js';
import Whentocreateclickbaitcontent from './articles/./Marketing/Buzz/When to create clickbait content.js';
import HowdoIcapitalizeonpositivefeedback from './articles/./Marketing/Community/How do I capitalize on positive feedback.js';
import HowdoIcontributetothegamedevcommunity from './articles/./Marketing/Community/How do I contribute to the gamedev community.js';
import HowdoIinvolvemycommunity from './articles/./Marketing/Community/How do I involve my community.js';
import HowdoImeetexpertsinmyfield from './articles/./Marketing/Community/How do I meet experts in my field.js';
import Howshouldmycommunitysavatarslook from './articles/./Marketing/Community/How should my communitys avatars look.js';
import Howtomakeagamecommunity from './articles/./Marketing/Community/How to make a game community.js';
import Howtomarketataconvention from './articles/./Marketing/Community/How to market at a convention.js';
import Doescrowdfundingworkformyproduct from './articles/./Marketing/Crowdfunding/Does crowdfunding work for my product.js';
import HowdoIavoidmycampaignslumping from './articles/./Marketing/Crowdfunding/How do I avoid my campaign slumping.js';
import HowdoIchargealotinmycampaign from './articles/./Marketing/Crowdfunding/How do I charge alot in my campaign.js';
import HowdoIfixfailedbackers from './articles/./Marketing/Crowdfunding/How do I fix failed backers.js';
import HowdoImakeacrowdfundingpresskit from './articles/./Marketing/Crowdfunding/How do I make a crowdfunding press kit.js';
import Howlongofacrowdfundingcampaign from './articles/./Marketing/Crowdfunding/How long of a crowdfunding campaign.js';
import Howmanycrowdfundingpresskits from './articles/./Marketing/Crowdfunding/How many crowdfunding press kits.js';
import Howmanycrowdfundingrewards from './articles/./Marketing/Crowdfunding/How many crowdfunding rewards.js';
import HowmuchdoIinvolvecommunityincrowdfunding from './articles/./Marketing/Crowdfunding/How much do I involve community in crowdfunding.js';
import Howmuchfeedbackdoesmycrowdfundpageneedbeforegoinglive from './articles/./Marketing/Crowdfunding/How much feedback does my crowdfund page need before going live.js';
import Howmuchtobudgetforcrowdfunding from './articles/./Marketing/Crowdfunding/How much to budget for crowdfunding.js';
import Howmuchtosetcrowdfundinggoalat from './articles/./Marketing/Crowdfunding/How much to set crowdfunding goal at.js';
import Howoftentopostduringcampaign from './articles/./Marketing/Crowdfunding/How often to post during campaign.js';
import Howtocreateacrowdfundingpage from './articles/./Marketing/Crowdfunding/How to create a crowdfunding page.js';
import Howtoinvolvecommunityincrowdfunding from './articles/./Marketing/Crowdfunding/How to involve community in crowdfunding.js';
import Howtomakecrowdfundingpageart from './articles/./Marketing/Crowdfunding/How to make crowdfunding page art.js';
import Whataccountshouldhostthecampaign from './articles/./Marketing/Crowdfunding/What account should host the campaign.js';
import Whatcrowdfundingplatformtouse from './articles/./Marketing/Crowdfunding/What crowdfunding platform to use.js';
import Whatcrowdfundingrewards from './articles/./Marketing/Crowdfunding/What crowdfunding rewards.js';
import WhatdoIdoonlaunchday from './articles/./Marketing/Crowdfunding/What do I do on launch day.js';
import WhatdoIdowithaudiencefeedback from './articles/./Marketing/Crowdfunding/What do I do with audience feedback.js';
import WhatdoIneedtodobeforestartingthecrowdfunding from './articles/./Marketing/Crowdfunding/What do I need to do before starting the crowdfunding.js';
import Whatdoesmycrowdfundingpageneed from './articles/./Marketing/Crowdfunding/What does my crowdfunding page need.js';
import Whattowriteonmycrowdfundingaccountabout from './articles/./Marketing/Crowdfunding/What to write on my crowdfunding account about.js';
import Whattowriteonmycrowdfundingpage from './articles/./Marketing/Crowdfunding/What to write on my crowdfunding page.js';
import WhenandwhatdatedoIlaunchacampaign from './articles/./Marketing/Crowdfunding/When and what date do I launch a campaign.js';
import Wheretofindanartistforcrowdfundingpages from './articles/./Marketing/Crowdfunding/Where to find an artist for crowdfunding pages.js';
import WhodoIgetcrowdfundingfeedbackfrom from './articles/./Marketing/Crowdfunding/Who do I get crowdfunding feedback from.js';
import HowdoImanageanFAQ from './articles/./Marketing/Customer Support/How do I manage an FAQ.js';
import HowdoImanagemissingpeices from './articles/./Marketing/Customer Support/How do I manage missing peices.js';
import HowdoImanagerulesclarifications from './articles/./Marketing/Customer Support/How do I manage rules clarifications.js';
import HowdoIplanasuccessfulevent from './articles/./Marketing/Event Planning/How do I plan a successful event.js';
import Doesmyproductworkwithguerrillamarketing from './articles/./Marketing/Guerrilla/Does my product work with guerrilla marketing.js';
import Howtosetupanurbanad from './articles/./Marketing/Guerrilla/How to setup an urban ad.js';
import Whatarethelawsaroundguerrillamarketing from './articles/./Marketing/Guerrilla/What are the laws around guerrilla marketing.js';
import HowdoIgetsponsorsandendorsements from './articles/./Marketing/How do I get sponsors and endorsements.js';
import HowdoItrackwhatmarketingworks from './articles/./Marketing/How do I track what marketing works.js';
import Howtoavoidfeelinglikeapromoter from './articles/./Marketing/How to avoid feeling like a promoter.js';
import Howtotalkaboutmygame from './articles/./Marketing/How to talk about my game.js';
import HowdoIcreatealandingpage from './articles/./Marketing/Landing/How do I create a landing page.js';
import HowdoIsetuplandingpageanalytics from './articles/./Marketing/Landing/How do I setup landing page analytics.js';
import Whatgoesinmylandingpage from './articles/./Marketing/Landing/What goes in my landing page.js';
import HowdoIformrelationshipswithmycommunity from './articles/./Marketing/Local/How do I form relationships with my community.js';
import WherecanIplaytestmygame from './articles/./Marketing/Local/Where can I playtest my game.js';
import Whatcontentdoreviewersandpressneed from './articles/./Marketing/Mediakits/What content do reviewers and press need.js';
import HowdoIknowmynewsletterisworking from './articles/./Marketing/Newsletter/How do I know my newsletter is working.js';
import Howisanewsletterdifferentfromtheblog from './articles/./Marketing/Newsletter/How is a newsletter different from the blog.js';
import HowmanynewslettersignupsdoIneed from './articles/./Marketing/Newsletter/How many newsletter signups do I need.js';
import HowmuchnewslettercontentshouldIcreate from './articles/./Marketing/Newsletter/How much newsletter content should I create.js';
import Howoftentowriteanewsletter from './articles/./Marketing/Newsletter/How often to write a newsletter.js';
import Howtoautomateanewsletter from './articles/./Marketing/Newsletter/How to automate a newsletter.js';
import Howtocollectemails from './articles/./Marketing/Newsletter/How to collect emails.js';
import HowtocomplywithGDPRandCANSPAM from './articles/./Marketing/Newsletter/How to comply with GDPR and CAN SPAM.js';
import WhatdoIprovideasincentivefornewslettersignup from './articles/./Marketing/Newsletter/What do I provide as incentive for newsletter signup.js';
import WhatinformationdoIcollectatsignup from './articles/./Marketing/Newsletter/What information do I collect at signup.js';
import Whatshouldmynewsletteremailscontain from './articles/./Marketing/Newsletter/What should my newsletter emails contain.js';
import Whattowriteinanewsletter from './articles/./Marketing/Newsletter/What to write in a newsletter.js';
import WheredoIlistmynewsletter from './articles/./Marketing/Newsletter/Where do I list my newsletter.js';
import Howtomake3Drendersofmygame from './articles/./Marketing/Photography/How to make 3D renders of my game.js';
import Howtophotographmygame from './articles/./Marketing/Photography/How to photograph my game.js';
import Howtophotographmyself from './articles/./Marketing/Photography/How to photograph myself.js';
import Howtorecordmygame from './articles/./Marketing/Photography/How to record my game.js';
import Wheretogetphotographyequipment from './articles/./Marketing/Photography/Where to get photography equipment.js';
import Howtocreateaboardgamegeekaccount from './articles/./Marketing/Platforms/How to create a board game geek account.js';
import Howtocreateadiscordcommunity from './articles/./Marketing/Platforms/How to create a discord community.js';
import Howtocreateatiktokaccount from './articles/./Marketing/Platforms/How to create a tiktok account.js';
import Howtocreateatwitteraccount from './articles/./Marketing/Platforms/How to create a twitter account.js';
import Howtocreateayoutubechannel from './articles/./Marketing/Platforms/How to create a youtube channel.js';
import HowmanyarticlesdoIseek from './articles/./Marketing/Press/How many articles do I seek.js';
import Howtocontactthepress from './articles/./Marketing/Press/How to contact the press.js';
import Howtocreateapresskit from './articles/./Marketing/Press/How to create a presskit.js';
import Howtosetupapressinterview from './articles/./Marketing/Press/How to set up a press interview.js';
import WhatdoIneedpresspeopletoknow from './articles/./Marketing/Press/What do I need press people to know.js';
import WhatpresspeopledoIsendto from './articles/./Marketing/Press/What press people do I send to.js';
import WhendoIcontactthepress from './articles/./Marketing/Press/When do I contact the press.js';
import DoIrequestarevieworapreview from './articles/./Marketing/Reviewers/Do I request a review or a preview.js';
import HowdoIchooseareviewer from './articles/./Marketing/Reviewers/How do I choose a reviewer.js';
import HowmanyreviewersdoIseek from './articles/./Marketing/Reviewers/How many reviewers do I seek.js';
import Howmuchdoessponsoringusuallycost from './articles/./Marketing/Reviewers/How much does sponsoring usually cost.js';
import Howtocontactanreviewer from './articles/./Marketing/Reviewers/How to contact an reviewer.js';
import Howtofindpeopletosponsor from './articles/./Marketing/Reviewers/How to find people to sponsor.js';
import Howtosendacopytopeople from './articles/./Marketing/Reviewers/How to send a copy to people.js';
import Whatcontentdoesasponsoredinfluencerneed from './articles/./Marketing/Reviewers/What content does a sponsored influencer need.js';
import Whatdoesareviewrequestlooklike from './articles/./Marketing/Reviewers/What does a review request look like.js';
import Whathappenswhenareviewerdoesntlikemygame from './articles/./Marketing/Reviewers/What happens when a reviewer doesnt like my game.js';
import WhendoIbookreviewers from './articles/./Marketing/Reviewers/When do I book reviewers.js';
import WhodoIsendcopiesto from './articles/./Marketing/Reviewers/Who do I send copies to.js';
import HowdoImakemywebsitegoodforSEO from './articles/./Marketing/Search Engine/How do I make my website good for SEO.js';
import HowdoIsetupupasponsoredsearchengineresult from './articles/./Marketing/Search Engine/How do I setup up a sponsored search engine result.js';
import Howtocreateagoogleadsearch from './articles/./Marketing/Search Engine/How to create a google ad search.js';
import WhatsearchtermsdoIoptimizemyproductfor from './articles/./Marketing/Search Engine/What search terms do I optimize my product for.js';
import Whatgoesinasellsheet from './articles/./Marketing/Sellsheets/What goes in a sellsheet.js';
import WhatprogramdoImakemysellsheetin from './articles/./Marketing/Sellsheets/What program do I make my sellsheet in.js';
import Arehashtagsimportant from './articles/./Marketing/Socials/Are hashtags important.js';
import HowdoIgetpeopletosubscribetomycontentmarketingsocial from './articles/./Marketing/Socials/How do I get people to subscribe to my content marketing social.js';
import HowdoIplansocialmediacontent from './articles/./Marketing/Socials/How do I plan social media content.js';
import HowdoIuseFacebookbusinessesandgroups from './articles/./Marketing/Socials/How do I use Facebook businesses and groups.js';
import Idonthavesocialaccounts from './articles/./Marketing/Socials/I dont have social accounts.js';
import WhatdoIuseeachsocialmediafor from './articles/./Marketing/Socials/What do I use each social media for.js';
import Whatplatformstomarketon from './articles/./Marketing/Socials/What platforms to market on.js';
import Howtocreateabillboardad from './articles/./Marketing/Traditional/How to create a billboard ad.js';
import Howtocreateaflyer from './articles/./Marketing/Traditional/How to create a flyer.js';
import Howtogetontheradio from './articles/./Marketing/Traditional/How to get on the radio.js';
import HowlongofavideodoIcreate from './articles/./Marketing/Video/How long of a video do I create.js';
import Howtocreateatiktok from './articles/./Marketing/Video/How to create a tiktok.js';
import Howtocreateatrailer from './articles/./Marketing/Video/How to create a trailer.js';
import Howtocreateimagesforanimations from './articles/./Marketing/Video/How to create images for animations.js';
import Howtomakeavideoaboutthecompany from './articles/./Marketing/Video/How to make a video about the company.js';
import WhatdoImakecontentabout from './articles/./Marketing/Video/What do I make content about.js';
import WhatkindofcontentdoImake from './articles/./Marketing/Video/What kind of content do I make.js';
import Whatdoesmymarketingfunnellooklike from './articles/./Marketing/What does my marketing funnel look like.js';
import Whatisthegoalofmarketing from './articles/./Marketing/What is the goal of marketing.js';
import WhodoImarketto from './articles/./Marketing/Who do I market to.js';
import HowdoImanagetaskorganization from './articles/./Mental Health/How do I manage task organization.js';
import HowdoImanagethestress from './articles/./Mental Health/How do I manage the stress.js';
import HowdoImanagethiswithafulltimejob from './articles/./Mental Health/How do I manage this with a full time job.js';
import HowtocreateaLLC from './articles/./Online/How to create a LLC.js';
import Howtocreateaboardgamewebsite from './articles/./Online/How to create a board game website.js';
import Howtocreateacompanywebsite from './articles/./Online/How to create a company website.js';
import Howtomakeaboardgamegeeklisting from './articles/./Online/How to make a board game geek listing.js';
import Howtosetupinpersonsales from './articles/./Online/How to set up in person sales.js';
import Howtosetupstripe from './articles/./Online/How to setup stripe.js';
import Howtotrackexpenses from './articles/./Online/How to track expenses.js';
import Wheretosellmygame from './articles/./Online/Where to sell my game.js';
import HowdoIhandleupdatingthegamepostlaunch from './articles/./Post Launch/How do I handle updating the game postlaunch.js';
import WhendoIcreateexpansions from './articles/./Post Launch/When do I create expansions.js';
import HowdoImanageregulations from './articles/./Regulations/How do I manage regulations.js';
import Howtosetupcomputer from './articles/./Situational/How to set up computer.js';
import HowtouseaCLI from './articles/./Situational/How to use a CLI.js';
import Howtousegit from './articles/./Situational/How to use git.js';
import IhaveChromebookorPCorOSX from './articles/./Situational/I have Chromebook or PC or OSX.js';
import Makinggamesisalotofwork from './articles/./Situational/Making games is alot of work.js';
import Noaccesstoacomputer from './articles/./Situational/No access to a computer.js';
import HowdoIgettheattentionofsomeonemoreimportantthanme from './articles/./Sourcing/How do I get the attention of someone more important than me.js';
import HowdoImanageateamofpeople from './articles/./Sourcing/How do I manage a team of people.js';
import HowdoImanagedocumentationformyteam from './articles/./Sourcing/How do I manage documentation for my team.js';
import HowdoInetwork from './articles/./Sourcing/How do I network.js';
import Howtoapproachanartist from './articles/./Sourcing/How to approach an artist.js';
import Howtoapproachboardgameretailers from './articles/./Sourcing/How to approach board game retailers.js';
import Howtocollaboratewithothers from './articles/./Sourcing/How to collaborate with others.js';
import Howtofindartists from './articles/./Sourcing/How to find artists.js';
import Imnotgoodatart from './articles/./Sourcing/Im not good at art.js';
import Noonetoplaywith from './articles/./Sourcing/No one to play with.js';
import Noonetoplaytestwith from './articles/./Sourcing/No one to playtest with.js';
import Notimetomakeart from './articles/./Sourcing/No time to make art.js';
import HowdoIknowIgotagoodpublishingdeal from './articles/./Sourcing/Publisher/How do I know I got a good publishing deal.js';
import Howtoapproachapublisher from './articles/./Sourcing/Publisher/How to approach a publisher.js';
import Wheretofindpublishers from './articles/./Sourcing/Publisher/Where to find publishers.js';
import WhendoIcollaborate from './articles/./Sourcing/When do I collaborate.js';
import HowdoImakesuremygameissustainablyproduced from './articles/./Sustainability/How do I make sure my game is sustainably produced.js';
import Ismygamemadewithslavelabor from './articles/./Sustainability/Is my game made with slave labor.js';
import Howoftentoplaytest from './articles/./Testing/How often to playtest.js';
import Howtobalanceagame from './articles/./Testing/How to balance a game.js';
import HowtocreateaTabletopplaygroundplaytest from './articles/./Testing/How to create a Tabletop playground playtest.js';
import Howtomakemygamereplayable from './articles/./Testing/How to make my game replayable.js';
import Howtoplaytest from './articles/./Testing/How to playtest.js';
import Howtotrainplaytesters from './articles/./Testing/How to train playtesters.js';

export const articles = {
  "Budgeting and Finances": {
    "How do I cheaply prototype": (props) => <HowdoIcheaplyprototype {...props} />,
    "How do I manage how much to spend": (props) => <HowdoImanagehowmuchtospend {...props} />,
    "How do I manage inventory": (props) => <HowdoImanageinventory {...props} />,
    "How do I manage risk": (props) => <HowdoImanagerisk {...props} />,
    "How much do I charge for the game": (props) => <HowmuchdoIchargeforthegame {...props} />
  },
  "How to start": (props) => <Howtostart {...props} />,
  "Ideation": {
    "How to make a game fun": (props) => <Howtomakeagamefun {...props} />,
    "How to make a meaningful game": (props) => <Howtomakeameaningfulgame {...props} />,
    "How to make high leverage games": (props) => <Howtomakehighleveragegames {...props} />,
    "What game to make": (props) => <Whatgametomake {...props} />
  },
  "Instantiation": {
    "How do I make my game accessible": (props) => <HowdoImakemygameaccessible {...props} />,
    "How do I make my game sensitive and inclusive": (props) => <HowdoImakemygamesensitiveandinclusive {...props} />,
    "How do I make my product stand out": (props) => <HowdoImakemyproductstandout {...props} />,
    "How to choose which idea to work on": (props) => <Howtochoosewhichideatoworkon {...props} />,
    "How to explain my game on the box": (props) => <Howtoexplainmygameonthebox {...props} />,
    "How to keep up with content treadmill": (props) => <Howtokeepupwithcontenttreadmill {...props} />,
    "How to make a board game box": (props) => <Howtomakeaboardgamebox {...props} />,
    "How to make art": (props) => <Howtomakeart {...props} />,
    "How to prototype my designs": (props) => <Howtoprototypemydesigns {...props} />,
    "How to rapid prototype": (props) => <Howtorapidprototype {...props} />,
    "How to translate my idea into a game": (props) => <Howtotranslatemyideaintoagame {...props} />,
    "How to use AI to make my game": (props) => <HowtouseAItomakemygame {...props} />,
    "How to write rules clearly": (props) => <Howtowriterulesclearly {...props} />,
    "What to prototype with": (props) => <Whattoprototypewith {...props} />
  },
  "Legal": {
    "How do I make a contract with contractors": (props) => <HowdoImakeacontractwithcontractors {...props} />,
    "How do I write a contract": (props) => <HowdoIwriteacontract {...props} />,
    "How not to get sued": (props) => <Hownottogetsued {...props} />,
    "How to protect my IP": (props) => <HowtoprotectmyIP {...props} />,
    "What data should I collect and what are the laws": (props) => <WhatdatashouldIcollectandwhatarethelaws {...props} />
  },
  "Manufacturing": {
    "Fulfillment": {
      "How to find a fulfillment company": (props) => <Howtofindafulfillmentcompany {...props} />,
      "What does a fulfillment company need": (props) => <Whatdoesafulfillmentcompanyneed {...props} />,
      "What is a fulfillment company": (props) => <Whatisafulfillmentcompany {...props} />
    },
    "How many boxes": (props) => <Howmanyboxes {...props} />,
    "How many manufacturers do I approach": (props) => <HowmanymanufacturersdoIapproach {...props} />,
    "How much to charge for the game": (props) => <Howmuchtochargeforthegame {...props} />,
    "How to contact a manufacturer": (props) => <Howtocontactamanufacturer {...props} />,
    "How to create print and play": (props) => <Howtocreateprintandplay {...props} />,
    "How to find a manufacturer": (props) => <Howtofindamanufacturer {...props} />,
    "How to make my game cheaply": (props) => <Howtomakemygamecheaply {...props} />,
    "How to upload to the GameCrafter": (props) => <HowtouploadtotheGameCrafter {...props} />,
    "Shipping": {
      "How do I make sure shipping is cheap": (props) => <HowdoImakesureshippingischeap {...props} />,
      "How much to charge shipping": (props) => <Howmuchtochargeshipping {...props} />,
      "How to avoid taxes and fees shipping overseas": (props) => <Howtoavoidtaxesandfeesshippingoverseas {...props} />,
      "What time of year to ship": (props) => <Whattimeofyeartoship {...props} />,
      "Who handles the shipping": (props) => <Whohandlestheshipping {...props} />
    },
    "What do manufacturers need": (props) => <Whatdomanufacturersneed {...props} />,
    "When to contact manufacturers": (props) => <Whentocontactmanufacturers {...props} />,
    "Who makes my game": (props) => <Whomakesmygame {...props} />
  },
  "Market Research": {
    "How do I get a sense of the market": (props) => <HowdoIgetasenseofthemarket {...props} />,
    "How do I know what otehrs are doing": (props) => <HowdoIknowwhatotehrsaredoing {...props} />
  },
  "Marketing": {
    "Ads": {
      "How do I create a facebook ad": (props) => <HowdoIcreateafacebookad {...props} />,
      "How much do I spend on marketing": (props) => <HowmuchdoIspendonmarketing {...props} />,
      "What platforms do I create ads on": (props) => <WhatplatformsdoIcreateadson {...props} />,
      "What should my ad look like": (props) => <Whatshouldmyadlooklike {...props} />
    },
    "Blogging": {
      "How often to write in my blog": (props) => <Howoftentowriteinmyblog {...props} />,
      "Is blogging right for my product": (props) => <Isbloggingrightformyproduct {...props} />,
      "What platform to blog in": (props) => <Whatplatformtoblogin {...props} />,
      "What to write in my blog": (props) => <Whattowriteinmyblog {...props} />,
      "Who to blog to": (props) => <Whotoblogto {...props} />
    },
    "Buzz": {
      "Does my product work with clickbait marketing": (props) => <Doesmyproductworkwithclickbaitmarketing {...props} />,
      "Should I use humor": (props) => <ShouldIusehumor {...props} />,
      "What is clickbait content": (props) => <Whatisclickbaitcontent {...props} />,
      "When to create clickbait content": (props) => <Whentocreateclickbaitcontent {...props} />
    },
    "Community": {
      "How do I capitalize on positive feedback": (props) => <HowdoIcapitalizeonpositivefeedback {...props} />,
      "How do I contribute to the gamedev community": (props) => <HowdoIcontributetothegamedevcommunity {...props} />,
      "How do I involve my community": (props) => <HowdoIinvolvemycommunity {...props} />,
      "How do I meet experts in my field": (props) => <HowdoImeetexpertsinmyfield {...props} />,
      "How should my communitys avatars look": (props) => <Howshouldmycommunitysavatarslook {...props} />,
      "How to make a game community": (props) => <Howtomakeagamecommunity {...props} />,
      "How to market at a convention": (props) => <Howtomarketataconvention {...props} />
    },
    "Crowdfunding": {
      "Does crowdfunding work for my product": (props) => <Doescrowdfundingworkformyproduct {...props} />,
      "How do I avoid my campaign slumping": (props) => <HowdoIavoidmycampaignslumping {...props} />,
      "How do I charge alot in my campaign": (props) => <HowdoIchargealotinmycampaign {...props} />,
      "How do I fix failed backers": (props) => <HowdoIfixfailedbackers {...props} />,
      "How do I make a crowdfunding press kit": (props) => <HowdoImakeacrowdfundingpresskit {...props} />,
      "How long of a crowdfunding campaign": (props) => <Howlongofacrowdfundingcampaign {...props} />,
      "How many crowdfunding press kits": (props) => <Howmanycrowdfundingpresskits {...props} />,
      "How many crowdfunding rewards": (props) => <Howmanycrowdfundingrewards {...props} />,
      "How much do I involve community in crowdfunding": (props) => <HowmuchdoIinvolvecommunityincrowdfunding {...props} />,
      "How much feedback does my crowdfund page need before going live": (props) => <Howmuchfeedbackdoesmycrowdfundpageneedbeforegoinglive {...props} />,
      "How much to budget for crowdfunding": (props) => <Howmuchtobudgetforcrowdfunding {...props} />,
      "How much to set crowdfunding goal at": (props) => <Howmuchtosetcrowdfundinggoalat {...props} />,
      "How often to post during campaign": (props) => <Howoftentopostduringcampaign {...props} />,
      "How to create a crowdfunding page": (props) => <Howtocreateacrowdfundingpage {...props} />,
      "How to involve community in crowdfunding": (props) => <Howtoinvolvecommunityincrowdfunding {...props} />,
      "How to make crowdfunding page art": (props) => <Howtomakecrowdfundingpageart {...props} />,
      "What account should host the campaign": (props) => <Whataccountshouldhostthecampaign {...props} />,
      "What crowdfunding platform to use": (props) => <Whatcrowdfundingplatformtouse {...props} />,
      "What crowdfunding rewards": (props) => <Whatcrowdfundingrewards {...props} />,
      "What do I do on launch day": (props) => <WhatdoIdoonlaunchday {...props} />,
      "What do I do with audience feedback": (props) => <WhatdoIdowithaudiencefeedback {...props} />,
      "What do I need to do before starting the crowdfunding": (props) => <WhatdoIneedtodobeforestartingthecrowdfunding {...props} />,
      "What does my crowdfunding page need": (props) => <Whatdoesmycrowdfundingpageneed {...props} />,
      "What to write on my crowdfunding account about": (props) => <Whattowriteonmycrowdfundingaccountabout {...props} />,
      "What to write on my crowdfunding page": (props) => <Whattowriteonmycrowdfundingpage {...props} />,
      "When and what date do I launch a campaign": (props) => <WhenandwhatdatedoIlaunchacampaign {...props} />,
      "Where to find an artist for crowdfunding pages": (props) => <Wheretofindanartistforcrowdfundingpages {...props} />,
      "Who do I get crowdfunding feedback from": (props) => <WhodoIgetcrowdfundingfeedbackfrom {...props} />
    },
    "Customer Support": {
      "How do I manage an FAQ": (props) => <HowdoImanageanFAQ {...props} />,
      "How do I manage missing peices": (props) => <HowdoImanagemissingpeices {...props} />,
      "How do I manage rules clarifications": (props) => <HowdoImanagerulesclarifications {...props} />
    },
    "Event Planning": {
      "How do I plan a successful event": (props) => <HowdoIplanasuccessfulevent {...props} />
    },
    "Guerrilla": {
      "Does my product work with guerrilla marketing": (props) => <Doesmyproductworkwithguerrillamarketing {...props} />,
      "How to setup an urban ad": (props) => <Howtosetupanurbanad {...props} />,
      "What are the laws around guerrilla marketing": (props) => <Whatarethelawsaroundguerrillamarketing {...props} />
    },
    "How do I get sponsors and endorsements": (props) => <HowdoIgetsponsorsandendorsements {...props} />,
    "How do I track what marketing works": (props) => <HowdoItrackwhatmarketingworks {...props} />,
    "How to avoid feeling like a promoter": (props) => <Howtoavoidfeelinglikeapromoter {...props} />,
    "How to talk about my game": (props) => <Howtotalkaboutmygame {...props} />,
    "Landing": {
      "How do I create a landing page": (props) => <HowdoIcreatealandingpage {...props} />,
      "How do I setup landing page analytics": (props) => <HowdoIsetuplandingpageanalytics {...props} />,
      "What goes in my landing page": (props) => <Whatgoesinmylandingpage {...props} />
    },
    "Local": {
      "How do I form relationships with my community": (props) => <HowdoIformrelationshipswithmycommunity {...props} />,
      "Where can I playtest my game": (props) => <WherecanIplaytestmygame {...props} />
    },
    "Mediakits": {
      "What content do reviewers and press need": (props) => <Whatcontentdoreviewersandpressneed {...props} />
    },
    "Newsletter": {
      "How do I know my newsletter is working": (props) => <HowdoIknowmynewsletterisworking {...props} />,
      "How is a newsletter different from the blog": (props) => <Howisanewsletterdifferentfromtheblog {...props} />,
      "How many newsletter signups do I need": (props) => <HowmanynewslettersignupsdoIneed {...props} />,
      "How much newsletter content should I create": (props) => <HowmuchnewslettercontentshouldIcreate {...props} />,
      "How often to write a newsletter": (props) => <Howoftentowriteanewsletter {...props} />,
      "How to automate a newsletter": (props) => <Howtoautomateanewsletter {...props} />,
      "How to collect emails": (props) => <Howtocollectemails {...props} />,
      "How to comply with GDPR and CAN SPAM": (props) => <HowtocomplywithGDPRandCANSPAM {...props} />,
      "What do I provide as incentive for newsletter signup": (props) => <WhatdoIprovideasincentivefornewslettersignup {...props} />,
      "What information do I collect at signup": (props) => <WhatinformationdoIcollectatsignup {...props} />,
      "What should my newsletter emails contain": (props) => <Whatshouldmynewsletteremailscontain {...props} />,
      "What to write in a newsletter": (props) => <Whattowriteinanewsletter {...props} />,
      "Where do I list my newsletter": (props) => <WheredoIlistmynewsletter {...props} />
    },
    "Photography": {
      "How to make 3D renders of my game": (props) => <Howtomake3Drendersofmygame {...props} />,
      "How to photograph my game": (props) => <Howtophotographmygame {...props} />,
      "How to photograph myself": (props) => <Howtophotographmyself {...props} />,
      "How to record my game": (props) => <Howtorecordmygame {...props} />,
      "Where to get photography equipment": (props) => <Wheretogetphotographyequipment {...props} />
    },
    "Platforms": {
      "How to create a board game geek account": (props) => <Howtocreateaboardgamegeekaccount {...props} />,
      "How to create a discord community": (props) => <Howtocreateadiscordcommunity {...props} />,
      "How to create a tiktok account": (props) => <Howtocreateatiktokaccount {...props} />,
      "How to create a twitter account": (props) => <Howtocreateatwitteraccount {...props} />,
      "How to create a youtube channel": (props) => <Howtocreateayoutubechannel {...props} />
    },
    "Press": {
      "How many articles do I seek": (props) => <HowmanyarticlesdoIseek {...props} />,
      "How to contact the press": (props) => <Howtocontactthepress {...props} />,
      "How to create a presskit": (props) => <Howtocreateapresskit {...props} />,
      "How to set up a press interview": (props) => <Howtosetupapressinterview {...props} />,
      "What do I need press people to know": (props) => <WhatdoIneedpresspeopletoknow {...props} />,
      "What press people do I send to": (props) => <WhatpresspeopledoIsendto {...props} />,
      "When do I contact the press": (props) => <WhendoIcontactthepress {...props} />
    },
    "Reviewers": {
      "Do I request a review or a preview": (props) => <DoIrequestarevieworapreview {...props} />,
      "How do I choose a reviewer": (props) => <HowdoIchooseareviewer {...props} />,
      "How many reviewers do I seek": (props) => <HowmanyreviewersdoIseek {...props} />,
      "How much does sponsoring usually cost": (props) => <Howmuchdoessponsoringusuallycost {...props} />,
      "How to contact an reviewer": (props) => <Howtocontactanreviewer {...props} />,
      "How to find people to sponsor": (props) => <Howtofindpeopletosponsor {...props} />,
      "How to send a copy to people": (props) => <Howtosendacopytopeople {...props} />,
      "What content does a sponsored influencer need": (props) => <Whatcontentdoesasponsoredinfluencerneed {...props} />,
      "What does a review request look like": (props) => <Whatdoesareviewrequestlooklike {...props} />,
      "What happens when a reviewer doesnt like my game": (props) => <Whathappenswhenareviewerdoesntlikemygame {...props} />,
      "When do I book reviewers": (props) => <WhendoIbookreviewers {...props} />,
      "Who do I send copies to": (props) => <WhodoIsendcopiesto {...props} />
    },
    "Search Engine": {
      "How do I make my website good for SEO": (props) => <HowdoImakemywebsitegoodforSEO {...props} />,
      "How do I setup up a sponsored search engine result": (props) => <HowdoIsetupupasponsoredsearchengineresult {...props} />,
      "How to create a google ad search": (props) => <Howtocreateagoogleadsearch {...props} />,
      "What search terms do I optimize my product for": (props) => <WhatsearchtermsdoIoptimizemyproductfor {...props} />
    },
    "Sellsheets": {
      "What goes in a sellsheet": (props) => <Whatgoesinasellsheet {...props} />,
      "What program do I make my sellsheet in": (props) => <WhatprogramdoImakemysellsheetin {...props} />
    },
    "Socials": {
      "Are hashtags important": (props) => <Arehashtagsimportant {...props} />,
      "How do I get people to subscribe to my content marketing social": (props) => <HowdoIgetpeopletosubscribetomycontentmarketingsocial {...props} />,
      "How do I plan social media content": (props) => <HowdoIplansocialmediacontent {...props} />,
      "How do I use Facebook businesses and groups": (props) => <HowdoIuseFacebookbusinessesandgroups {...props} />,
      "I dont have social accounts": (props) => <Idonthavesocialaccounts {...props} />,
      "What do I use each social media for": (props) => <WhatdoIuseeachsocialmediafor {...props} />,
      "What platforms to market on": (props) => <Whatplatformstomarketon {...props} />
    },
    "Traditional": {
      "How to create a billboard ad": (props) => <Howtocreateabillboardad {...props} />,
      "How to create a flyer": (props) => <Howtocreateaflyer {...props} />,
      "How to get on the radio": (props) => <Howtogetontheradio {...props} />
    },
    "Video": {
      "How long of a video do I create": (props) => <HowlongofavideodoIcreate {...props} />,
      "How to create a tiktok": (props) => <Howtocreateatiktok {...props} />,
      "How to create a trailer": (props) => <Howtocreateatrailer {...props} />,
      "How to create images for animations": (props) => <Howtocreateimagesforanimations {...props} />,
      "How to make a video about the company": (props) => <Howtomakeavideoaboutthecompany {...props} />,
      "What do I make content about": (props) => <WhatdoImakecontentabout {...props} />,
      "What kind of content do I make": (props) => <WhatkindofcontentdoImake {...props} />
    },
    "What does my marketing funnel look like": (props) => <Whatdoesmymarketingfunnellooklike {...props} />,
    "What is the goal of marketing": (props) => <Whatisthegoalofmarketing {...props} />,
    "Who do I market to": (props) => <WhodoImarketto {...props} />
  },
  "Mental Health": {
    "How do I manage task organization": (props) => <HowdoImanagetaskorganization {...props} />,
    "How do I manage the stress": (props) => <HowdoImanagethestress {...props} />,
    "How do I manage this with a full time job": (props) => <HowdoImanagethiswithafulltimejob {...props} />
  },
  "Online": {
    "How to create a LLC": (props) => <HowtocreateaLLC {...props} />,
    "How to create a board game website": (props) => <Howtocreateaboardgamewebsite {...props} />,
    "How to create a company website": (props) => <Howtocreateacompanywebsite {...props} />,
    "How to make a board game geek listing": (props) => <Howtomakeaboardgamegeeklisting {...props} />,
    "How to set up in person sales": (props) => <Howtosetupinpersonsales {...props} />,
    "How to setup stripe": (props) => <Howtosetupstripe {...props} />,
    "How to track expenses": (props) => <Howtotrackexpenses {...props} />,
    "Where to sell my game": (props) => <Wheretosellmygame {...props} />
  },
  "Post Launch": {
    "How do I handle updating the game postlaunch": (props) => <HowdoIhandleupdatingthegamepostlaunch {...props} />,
    "When do I create expansions": (props) => <WhendoIcreateexpansions {...props} />
  },
  "Regulations": {
    "How do I manage regulations": (props) => <HowdoImanageregulations {...props} />
  },
  "Situational": {
    "How to set up computer": (props) => <Howtosetupcomputer {...props} />,
    "How to use a CLI": (props) => <HowtouseaCLI {...props} />,
    "How to use git": (props) => <Howtousegit {...props} />,
    "I have Chromebook or PC or OSX": (props) => <IhaveChromebookorPCorOSX {...props} />,
    "Making games is alot of work": (props) => <Makinggamesisalotofwork {...props} />,
    "No access to a computer": (props) => <Noaccesstoacomputer {...props} />
  },
  "Sourcing": {
    "How do I get the attention of someone more important than me": (props) => <HowdoIgettheattentionofsomeonemoreimportantthanme {...props} />,
    "How do I manage a team of people": (props) => <HowdoImanageateamofpeople {...props} />,
    "How do I manage documentation for my team": (props) => <HowdoImanagedocumentationformyteam {...props} />,
    "How do I network": (props) => <HowdoInetwork {...props} />,
    "How to approach an artist": (props) => <Howtoapproachanartist {...props} />,
    "How to approach board game retailers": (props) => <Howtoapproachboardgameretailers {...props} />,
    "How to collaborate with others": (props) => <Howtocollaboratewithothers {...props} />,
    "How to find artists": (props) => <Howtofindartists {...props} />,
    "Im not good at art": (props) => <Imnotgoodatart {...props} />,
    "No one to play with": (props) => <Noonetoplaywith {...props} />,
    "No one to playtest with": (props) => <Noonetoplaytestwith {...props} />,
    "No time to make art": (props) => <Notimetomakeart {...props} />,
    "Publisher": {
      "How do I know I got a good publishing deal": (props) => <HowdoIknowIgotagoodpublishingdeal {...props} />,
      "How to approach a publisher": (props) => <Howtoapproachapublisher {...props} />,
      "Where to find publishers": (props) => <Wheretofindpublishers {...props} />
    },
    "When do I collaborate": (props) => <WhendoIcollaborate {...props} />
  },
  "Sustainability": {
    "How do I make sure my game is sustainably produced": (props) => <HowdoImakesuremygameissustainablyproduced {...props} />,
    "Is my game made with slave labor": (props) => <Ismygamemadewithslavelabor {...props} />
  },
  "Testing": {
    "How often to playtest": (props) => <Howoftentoplaytest {...props} />,
    "How to balance a game": (props) => <Howtobalanceagame {...props} />,
    "How to create a Tabletop playground playtest": (props) => <HowtocreateaTabletopplaygroundplaytest {...props} />,
    "How to make my game replayable": (props) => <Howtomakemygamereplayable {...props} />,
    "How to playtest": (props) => <Howtoplaytest {...props} />,
    "How to train playtesters": (props) => <Howtotrainplaytesters {...props} />
  }
};