


import React from "react";
import "../LoginForms.css"

export default class EmailPassword extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="input-group input-group-sm" data-bs-theme="dark">
                    <span className="input-group-text">Email Address</span>
                    <input type="email" className="form-control" placeholder="john-smith@mail.com" 
                        value={this.props.email} 
                        onChange={(e)=> this.props.updateEmailCallback(e.target.value)}
                    />
                </div>
                <div className="input-group input-group-sm" data-bs-theme="dark">
                    <span className="input-group-text">Password</span>
                    <input type="password" className="form-control" placeholder=""
                        value={this.props.password} 
                        onChange={(e)=> this.props.updatePasswordCallback(e.target.value)} 
                    />
                </div>
            </React.Fragment>
        );
    }
}