import React from "react";
import HeadsUp from "../../HeadsUp";

export default class CreatingyourFirstProject extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Creating your First Project</h1>
            <p>When you first open Templative, you need to login to your Templative account.</p>
            <p>To create a Templative project, click the Create New Project in Directory button. When the file system dialog, create a new folder for your project and click Create. This will create the basic Templative project structure in your new folder.</p>
            <HeadsUp message="If you are using Git or other Version Control, now is a good time to run `git init`."/>
            <p>Templative projects are a collection of components such as boxes, decks, dice, and etc. To get started making components, go to the <a href={`${window.location.origin}/docs/1-making-your-game/creating-components`}>Creating Components</a> article.</p>
        </React.Fragment>
    }
}