
import React from "react";
import tabs from "./tabs.png"

export default class UnderstandingyourTools extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Understanding your Tools</h1>
            <p>Templative provides you with many tools to be successful at board game production. These are split into various tables within Templative.</p>
            {/* <img src={tabs} alt="Templative's tabs"/> */}
            <p>Templative has the following tabs:</p>
            <ul>
                <li><a href={`/docs/1-making-your-game/creating-components`}>Component Creation screen</a>: name and create new components.</li>
                <li><a href={`/docs/1-making-your-game/editing-your-components/component-compose`}>Edit screen</a>: modify the game's content, art, and how it is assembled.</li>
                <li><a href={`/docs/1-making-your-game/rendering-your-game`}>Render screen</a>: render all or a piece of your game.</li>
                <li><a href={`/docs/2-exporting/creating-a-print-and-play`}>Print screen</a>: create pdfs of your board game for home printing.</li>
                <li><a href={`/docs/2-exporting/exporting-to-tabletop-simulator`}>Tabletop Simulator screen</a>: create Tabletop Simulator saves to later publish for sharing.</li>
                <li><a href={`/docs/2-exporting/exporting-to-tabletop-playground`}>Tabletop Playground screen</a>: create Tabletop Playground packages for later publishing for sharing. We included Tabletop Playground as the <a href="https://discord.com/invite/breakmygame">Break my Game Discord</a> doesn't allow Tabletop Simulator.</li>
                <li><a href={`/docs/2-exporting/uploading-to-the-game-crafter`}>TheGameCrafter screen</a>: Upload your rendered game to TheGameCrafter for prototyping.</li>
                <li><a href={`/docs/3-sharing-your-game/attending-a-convention`}>The Convention Search screen</a>: Find and learn about conventions near you.</li>
                <li><a href={`/docs/3-sharing-your-game/finding-a-publisher`}>The Publisher Search and Match screen</a>: Find and learn about publishers near you.</li>
                <li>The Feedback screen: Send me feedback.</li>
            </ul>
        </React.Fragment>
    }
}