import axios from "axios";
import {getOS} from "./Utility"
import { getCookie } from "./CookieManagement";
import ReactGA from 'react-ga4';

export const postADemoRequest = async (email, imageFile) => {
    
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
    const baseurl = isLocalhost ? "http://localhost:5000" : "https://www.templative.net";

    const formData = new FormData();
    formData.append('email', email);
    formData.append('image', imageFile);

    const response = await axios.post(`${baseurl}/demo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;    
};

export const getYoutubeShorts = async () => {
    try {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        const baseurl = isLocalhost ? "http://localhost:5000" : "https://www.templative.net"
        var response = await axios.get(`${baseurl}/youtube/shorts`)
        return response.data
    }
    catch(error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    }
    return []
}
export const sendReminderEmail = async (email) => {
    ReactGA.event({
        category: 'User',
        action: 'Reminder Sent',
        label: 'Registration',
      });
    window.rdt('track', 'Custom', {customEventName:'Reminder Sent'});
    try {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        const baseurl = isLocalhost ? "http://localhost:5000" : "https://www.templative.net"
        var response = await axios.post(`${baseurl}/reminder`, { 
            email: email, 
        })
        return response.status === 200
    }
    catch(error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    }
}
export const attemptPurchase = async () => {
    ReactGA.event({
        category: 'Ecommerce',
        action: 'Started Templative Purchase',
        label: 'Transaction',
      });
    window.rdt('track', 'Custom', {customEventName:'Started Templative Purchase'});
    
    var token = getCookie("TEMPLATIVE_TOKEN")
    var email = getCookie("TEMPLATIVE_EMAIL")
    if (email === null || token === null) {
        window.location.href = `${window.location.origin}/login`  
    }
    try {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        const baseurl = isLocalhost ? "http://localhost:5000" : "https://www.templative.net"
        var response = await axios.post(`${baseurl}/create-checkout`, { 
            email: email, 
            productGuid: "TEMPLATIVE"
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        switch (response.status) {
            case 200:
                console.log(response.data.url)
                window.location.replace(response.data.url);
                break;
            case 400:
                console.log(response.data.message)
                break;
            case 401:
                window.location.href = `${window.location.origin}/login`  
                break;
            case 403:
                window.location.href = `${window.location.origin}/login`  
                break;
            case 409: 
                console.log("You already own Templative!")
                break;
            default:
                console.log("There was an issue with purchasing.")
                break;
        }
    }
    catch(error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    }
}

export const checkIfTokenIsValid = async(email, token) => {
    try {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        const baseurl = isLocalhost ? "http://127.0.0.1:5000" : "https://www.templative.net"
        var response = await axios.post(`${baseurl}/validate-token`, { email }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        console.log(response)
        return response.data.isValid
    }
    catch(error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    }
    return false
}

export const doesUserOwnTemplative = async(email, token) => {
    if (email === undefined) {
        console.error("email cannot be undefined")
        return false
    }
    if (token === undefined) {
        console.error("token cannot be undefined")
        return false
    }
    try {
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
        const baseurl = isLocalhost ? "http://127.0.0.1:5000" : "https://www.templative.net"
        var response = await axios.get(`${baseurl}/product?email=${encodeURIComponent(email)}&productGuid=${"TEMPLATIVE"}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return response.data.hasProduct
    }
    catch(error) {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    }
    return false
}

export const attemptTemplativeDownload = async () => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname === '::1';
    const baseurl = isLocalhost ? "http://127.0.0.1:5000" : "https://www.templative.net"
    const url = `${baseurl}/download?processplatform=${getOS()}`
    await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(startTemplativeDownload)
    .catch(error => {
        if (error.response) {
            console.log(`Error status code: ${error.response.status}`);
            console.log(`Error details: ${error.response.data}`);
        } else if (error.request) {
            console.log("No response was received from the server.");
        } else {
            console.log('Error', error.message);
        }
    });
}
const startTemplativeDownload = async (response) => {
    console.log(response)
    const filepath = response.data["filepath"]
    const link = document.createElement('a');
    link.href = filepath; // Direct URL to the S3 file
    var tokens = filepath.split("/");
    link.setAttribute('download', tokens[tokens.length-1]);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}