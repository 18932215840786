import React from "react";

export default class WhatisTemplative extends React.Component {
    render() {
        return <React.Fragment>
            <h1>What is Templative</h1>
            <p>Templative is a board game production desktop app. You provide it svg art files, content, and commands, and Templative will render and package your game for you.</p>
            
            <h2>Making Games with Templative</h2>
            <p>For each of the components specified in your <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/component-compose`}>Component Compose</a>, Templative takes your svg <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/art`}>Art</a> files and uses the commands you specify in your <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/artdata`}>Artdata</a> to place content from your <a href={`${window.location.origin}/docs/1-making-your-game/editing-your-components/gamedata`}>Gamedata</a> in the art. This creates a <a href={`${window.location.origin}/docs/1-making-your-game/rendering-your-game`}>render</a> of your game. Renders can be exported for <a href={`${window.location.origin}/docs/2-exporting/creating-a-print-and-play`}>home printing,</a> playtesting with Tabletop <a href={`${window.location.origin}/docs/2-exporting/exporting-to-tabletop-simulator`}>Simulator</a> and <a href={`${window.location.origin}/docs/2-exporting/exporting-to-tabletop-playground`}>Playground</a>, and prototyping with <a href={`${window.location.origin}/docs/2-exporting/uploading-to-the-game-crafter`}>TheGameCrafter</a>.</p>
            <p>Templative relies on Inkscape under the hood. Though nothing is stopping you from using Illustrator to create svgs for Templative.</p> 
            <p>Templative watches your filesystem for changes, and all files are given in a human readable format, meaning Drive apps and Git are compatible with Templative.</p>
            
            <p>From there, you can <a href={`${window.location.origin}/docs/3-sharing-your-game/attending-a-convention`}>attend conventions</a> and <a href={`${window.location.origin}/docs/3-sharing-your-game/finding-a-publisher`}>seek publishers</a>.</p>
        </React.Fragment>
    }
}