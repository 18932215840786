import React from "react";
// import HeadsUp from "../../../../Documentation/HeadsUp";
// import TemplativePurchaseSuggestion from "../../../../Documentation/TemplativePurchaseSuggestion";

export default class Howoftentopostduringcampaign extends React.Component {
    render() {
        return <React.Fragment>
            <h1>How often to post during campaign</h1>
            <p></p>
        </React.Fragment>
    }
}