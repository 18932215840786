import React from "react";
// import HeadsUp from "../../../../Documentation/HeadsUp";
// import TemplativePurchaseSuggestion from "../../../../Documentation/TemplativePurchaseSuggestion";

export default class WhodoIgetcrowdfundingfeedbackfrom extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Who do I get crowdfunding feedback from</h1>
            <p></p>
        </React.Fragment>
    }
}