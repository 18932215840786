import React from "react";
import "../TopBar.css"

import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import NavbarLink from "./NavbarLink";
import Nav from 'react-bootstrap/Nav';
import { clearCookie,getCookie } from "../../../../CookieManagement";
import { checkIfTokenIsValid, doesUserOwnTemplative } from "../../../../TemplativeClient";

export class TopBar extends React.Component {    
    render() {
        var isAwayFromHome = window.location.pathname !== "" && window.location.pathname !== "/"
        return (
            <Navbar fixed="top" className="top-bar-navbar">
                <Container>
                    <Navbar.Brand>
                        <Link to="/">{this.props.mainPageName}</Link>
                    </Navbar.Brand>
                    <Nav className="justify-content-end">
                        <p className="email">{this.props.email}</p>
                        
                        <NavbarLink endpointName="Home" isGlobalEndpoint={false} endpoint={""}/>
                        <NavbarLink endpointName="Docs" isGlobalEndpoint={false} endpoint={"docs/0-getting-started/what-is-templative"}/>
                        {/* <NavbarLink endpointName="Articles" isGlobalEndpoint={false} endpoint={"articles/how-to-start"}/> */}
                        {/* { this.props.isLoggedIn ?
                            <>
                                { (!this.props.doesOwnTemplative && window.location.pathname !== "/purchase") &&
                                    <NavbarLink endpointName="Buy Now" isGlobalEndpoint={false} endpoint={"purchase"}/>
                                }{ this.props.doesOwnTemplative && window.location.pathname !== "/bonus" &&
                                    <NavbarLink endpointName="My Bonuses" isGlobalEndpoint={false} endpoint={"bonus"}/>
                                } 
                                { window.location.pathname !== "/update-password" &&
                                    <NavbarLink endpointName="Update Password" isGlobalEndpoint={false} endpoint={"update-password"}/>
                                }
                                <span className="topbar-link nav-link logout-button" onClick={this.props.logoutCallback}>Logout</span>
                            </>
                            : 
                            <>
                                { window.location.pathname !== "/login" &&
                                    <NavbarLink endpointName="Sign In" isGlobalEndpoint={false} endpoint={"login"}/>
                                }
                                { window.location.pathname !== "/register" &&
                                    <NavbarLink endpointName="Register" isGlobalEndpoint={false} endpoint={"register"}/>
                                }
                            </>
                                
                        } */}
                        {/* { window.location.pathname !== "/thanks" &&
                            <NavbarLink endpointName="Thanks" isGlobalEndpoint={false} endpoint={"thanks"}/>
                        } */}
                    </Nav>                                       
                </Container>
            </Navbar>
        );
    }
}