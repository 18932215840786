
import React from "react";
import YouTube from 'react-youtube';
import ReactGA from 'react-ga4';
import { attemptTemplativeDownload } from "../../../../TemplativeClient";

export default class InstallingtheTools extends React.Component {
    state = {
        isDownloading: false
    }
    static getOS = () => {
        var platform = window.navigator.platform
        var macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
        var windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      
        if (macosPlatforms.indexOf(platform) !== -1) {
          return 'darwin';
        } 
        if (windowsPlatforms.indexOf(platform) !== -1) {
          return 'win32';
        }
      
        return undefined;
    }
    _onReady(event) {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    _onPlay() {
        window.rdt('track', 'Custom', {customEventName:'playInkscapeVideo'});
      }
    clickDownloadButton = async () => {
        if (InstallingtheTools.getOS() === undefined || this.state.isDownloading) {
            return false
        }
        ReactGA.event({
            category: 'User',
            action: 'Templative Downloaded',
            label: 'App Download',
          });
        window.rdt('track', 'Custom', {customEventName:'Templative Downloaded'});
        this.setState({isDownloading: true}, async () => {
            await attemptTemplativeDownload()
            this.setState({isDownloading: false})
        })
    }  
    render() {
        return <React.Fragment>
            <h1>Installing the Tools</h1>
            <p>Templative relies on Inkscape to render your art files. Inkscape is a free vector art program.</p>
            <p><a href="https://www.inkscape.org" className="download-inkscape">Download Inkscape</a>.</p>
            <p>If you are unfamiliar with Inkscape, watch my 5 minute crash course on using Inkscape.</p>
            <YouTube videoId="L6F7X9YSJCU" opts={{ height: '390', width: '640', playerVars: { autoplay: 0} }} onReady={this._onReady} onPlay={this._onPlay} />
            <p>Next you will need Templative.</p>
            <button 
                onClick={this.clickDownloadButton} 
                disabled={InstallingtheTools.getOS() === undefined || this.state.isDownloading}
                type="button" className="btn btn-primary secondary-action-button download-templative-now-button"
            >
                Download Templative
            </button>
            <p>If you are on Mac, place both apps in your Applications folder.</p>
        </React.Fragment>
    }
}