import React from "react";
// import HeadsUp from "../../../../Documentation/HeadsUp";
// import TemplativePurchaseSuggestion from "../../../../Documentation/TemplativePurchaseSuggestion";

export default class HowdoIcreateafacebookad extends React.Component {
    render() {
        return <React.Fragment>
            <h1>How do I create a facebook ad</h1>
            <p></p>
        </React.Fragment>
    }
}