
import React from "react";
import "./DocumentationLanding.css"
import Documentation from "./Documentation";
import ReactGA from 'react-ga4';
import {  Route, Routes } from "react-router-dom";
import {articles} from "./articles"
import {Helmet} from "react-helmet"

export default class DocumentationLanding extends React.Component {
    
    state = {
        
    }
    componentDidMount = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Visited Documentation',
            label: 'Registration',
          });
        window.rdt('track', 'Visited Documentation');
    }
    generateNavLinks = (articles, basePath = "") => {
        return Object.keys(articles).map(key => {
            const path = `${basePath}/${key.replace(/\s+/g, '-').toLowerCase()}`;
            const isActive = window.location.pathname === "/docs" + path
            const activeClass = isActive ? 'clickable-doc-link current-doc-link' : 'clickable-doc-link';
            if (typeof articles[key] === 'object' && !React.isValidElement(articles[key])) {
                return (
                    <li key={path}>
                        <div className="doc-folder">{key}</div>
                        <ul>{this.generateNavLinks(articles[key], path)}</ul>
                    </li>
                );
            } else {
                // This is an end node, generate a link
                return (
                    <li className={activeClass} key={path}>
                        <a href={`${window.location.origin}/docs${path}`}>{key}</a>
                    </li>
                );
            }
        });
    }

    // Recursive function to generate routes
    generateRoutes = (articles, basePath = "") => {
        return Object.keys(articles).map(key => {
            const path = `${basePath}/${key.replace(/\s+/g, '-').toLowerCase()}`;
            if (typeof articles[key] === 'object' && !React.isValidElement(articles[key])) {
                var routes = this.generateRoutes(articles[key], path);
                return routes
            } else {
                const DocumentationPage = articles[key];
                return (
                    <Route key={path} path={path} element={
                        <React.Fragment>
                            <Helmet>
                                <meta charSet="utf-8" />
                                <title>{key}</title>
                                <link rel="canonical" href={"https://www.templative.net/docs"+path}  />
                            </Helmet>
                            <DocumentationPage isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative} />
                        </React.Fragment>
                    } />
                );
            }
        });
    }
    render() {
        return (
            <div className="row documentation">
                <div className="col-12 col-xl-2 documentation-table-of-contents">
                    <ul className="outermost-table-of-contents">
                        {this.generateNavLinks(articles)}
                    </ul>
                </div>
                <div className="col documentation-content">
                    <div className="documentation-readability-wrapper">
                        <Routes>
                            <Route path={"/"} element={<Documentation/>} />
                            {this.generateRoutes(articles)}
                        </Routes>
                        <div className="need-help">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" className="bi bi-question-octagon" viewBox="0 0 16 16">
                                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1z"/>
                                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                            </svg>
                            <p><span className="help-emphasis">Didn't get your question answered?</span> Consider posting your question in the <a href="https://discord.gg/GN3vgjHegK">Templative Discord</a> in the #troubleshooting chat.</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}