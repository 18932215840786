
import React from "react";
import TemplativePurchaseSuggestion from "../../TemplativePurchaseSuggestion";
import publisherMap from "./publisherMap.png"
import publisherSearch from "./publisherSearch.png"
import HeadsUp from "../../HeadsUp";

export default class FindaPublisher extends React.Component {
    render() {
        return <React.Fragment>
            <h1>Finding a Publisher</h1>
            <p>Publishers help with marketing, manufacturing, logistics, art, and are generally a great source of industry experience.</p>
            <TemplativePurchaseSuggestion isLoggedIn={this.props.isLoggedIn} doesOwnTemplative={this.props.doesOwnTemplative}/>
            <p>To find Publishers near you, as well as their preferences and commonly attending conventions within Templative, go to the Find Conventions and Publishers map.</p>
            <img src={publisherMap} alt="Publisher map."/>
            <h2>Finding Potential Publishers</h2>
            <p>Within the Publisher and Conventions map screen, fill out the Publisher Search form for your game, then click Search for a Publisher.</p>
            <img src={publisherSearch} alt="Publisher map."/>
            <HeadsUp message="The Publisher Match feature takes around 10 seconds to complete."/>
            <p>Look up these publishers, make sure they are still accepting submissions, observe their submission guidelines, and fire off a pitch. You can do it!</p>
        </React.Fragment>
    }
}